@import url("https://use.typekit.net/gur3pcq.css");

$red: #b1323b;
$white: #f8f0d9;
// $white: #fff;
$black: #0a0a0a;
$borderWidth: 2px;
$paddingY: 0.7em;
$max-site-width: 1280px;

$sigFont: poynter-gothic-text, sans-serif;
// $sigFont: courier, monospace;
$sigFontCompressed: poynter-gothic-text-condense;
$deco: poynter-gothic-text, sans-serif;
$script: poynter-gothic-text, sans-serif;

:root {
  // color-scheme: light dark;
}
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

.china1.site-content {
  .site-notice-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;
  }
  // background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
  background-color: $white;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='64' viewBox='0 0 48 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M48 28v-4L36 12 24 24 12 12 0 24v4l4 4-4 4v4l12 12 12-12 12 12 12-12v-4l-4-4 4-4zM8 32l-6-6 10-10 10 10-6 6 6 6-10 10L2 38l6-6zm12 0l4-4 4 4-4 4-4-4zm12 0l-6-6 10-10 10 10-6 6 6 6-10 10-10-10 6-6zM0 16L10 6 4 0h4l4 4 4-4h4l-6 6 10 10L34 6l-6-6h4l4 4 4-4h4l-6 6 10 10v4L36 8 24 20 12 8 0 20v-4zm0 32l10 10-6 6h4l4-4 4 4h4l-6-6 10-10 10 10-6 6h4l4-4 4 4h4l-6-6 10-10v-4L36 56 24 44 12 56 0 44v4z' fill='%23e7e7e7' fill-opacity='0.2' fill-rule='evenodd'/%3E%3C/svg%3E");
  a {
    // color: #770092;
    font-weight: 400;
  }
  .menu-item {
    .photo-container {
      // width: 400px;
    }
  }
}


.china1 {
  font-family: $sigFont;
  .product-link {
    background: #407841;
    border: none;
    color: $white;
    display: flex;
    align-items: center;
    // min-width: 2em;
    min-height: 1em;
    .plus {
      font-weight: 200;
      position: relative;
      top: -0.02em;
      font-size: 2em;
      line-height: 0.3em;
    }
    &:visited {
      color: $white;
    }
  }
  .carousel-hero {
    // max-height: 400px;
    margin-bottom: 2.5em;
    button {
      box-shadow: none;
      border-radius: none;
      font-size: 0.6em;
    }
  }
  .landing-page-wrapper {
    max-width: $max-site-width;
    margin: 0 auto;

  }
  .info {
    margin: 1em 0;
    text-align: center;
    line-height: 1.4em;
    font-size: 1em;
  }
  .text-section {
    text-align: center;
    line-height: 1.4em;
    .image-header {
      h1 {
        padding: 1em 1em;
        background: black;
        color: white;
        border: 6px solid $red;
        border-top: none;
        border-bottom: none;
      }
      margin-bottom: 2em;
    }
    .header {
      padding: 1em 0;
      font-family: $deco;
      font-weight: 900;
      .title {
        font-size: 1.5em;
      }
    }
    .content {
      font-size: 0.9em;
      max-width: 570px;
      margin: 0 auto;
      padding: 0 0.5em;
      font-family: $sigFont;
    }
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    // background: none !important;
    color: $white;
    background: red;
    // text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    // padding: 0em 0.3em !important;
    // font-size: 1em;
    // font-weight: 900 !important;
  }

  svg.nav-bar-logo {
    display: block;
    position: relative;
    height: 35px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    // padding-top: 0.23em;
    padding-left: 0.3em;
    // padding-bottom: 0.023em;
  }

  .cart.minimized {
    box-sizing: border-box;
    max-width: $max-site-width;
    margin: 0 auto;
    // padding: 0.3em 0;
    padding-left: 0;
    // padding-bottom: 0.1em;
    background-color: $white;
    color: black;
    // background-image: url("data:image/svg+xml,%3Csvg width='5' height='5' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%235cbd72' fill-opacity='0.4' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E");
    // margin-top: -0.5em;
    // background-color: #00d8ce;
    // background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23010202' fill-opacity='0.29' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
    border: none;
    // border-bottom: 2.5px solid #fcdd2d;

  }
  // .hide {
  //   // position: absolute;
  //   width: 100%;
  // }
  .hero-wrapper {
    display: flex;
    align-items: flex-end;
  }
  .logo-banner {
    // padding-left: 1em;
    // padding-right: 0.5em;
    padding-bottom: 0;
    margin-bottom: 0;
    width: 30%;
    max-width: 200px;
    // padding: 0 1em 0 1em;
    svg.hero-logo {

    }
    img.hero-logo {
      display: inline-block;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  a.order-online-button {
    // margin: 2em 0;
    font-size: 0.8em;
    display: inline-block;
    color: $white;
    text-decoration: none;
    padding: 0.8em 0.8em;
    margin-right: 1.5em;
    margin-left: 1.5em;
    margin-bottom: 0.5em;
    background: $red;
    font-family: $sigFontCompressed;
    font-weight: 600;
    box-shadow: none;
    // margin-right: 0.5em;
    // margin: 0.5em;
    // margin-left: 0;
    border-radius: 5px;
    border: 2px solid $white;
    background: linear-gradient(90deg, #008552 50%, #9ebd13 100%);
    box-shadow: 1px 1px #0e3e22, 3px 3px #0e3e22, 5px 5px #0e3e22, 7px 7px #0e3e22;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #af7b31, 3px 3px #af7b31, 5px 5px #af7b31, 7px 7px #af7b31;
    }
  }
  .hero-banner {
    // width: 70%;
    flex: 1;
    padding: 1em 0;
    // background-color: red;
    min-height: 400px;
    background: url(https://afag.imgix.net/china-1/wok-1.jpg?w=1280&auto=format) no-repeat;
    background-size: cover;
    background-position: center 65%;
    // background: url(https://afag.imgix.net/lancers-family-restaurant/salads.jpg?w=1200) no-repeat;
    // @media screen and (min-width: 1000px) {
    // background-image: url(https://afag.imgix.net/playa-del-carmen-seafood-and-grill/hero.jpg?w=1200&auto=format);
    // }



    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    text-shadow: 1px 1px 1px solid white;
    margin-bottom: 0em;

    .icon {
      width: 300px;
      animation-name: spin;
      animation-duration: 500ms;
    }
    @keyframes spin {
      from {
        transform:rotate(0deg) scale(0);
      }
      to {transform:rotate(360deg) scale(1);}
    }

    .text {
      color: black;
      background: white;
      // text-shadow: 1px 1px 1px black,
      //               2px 2px 1px black;
      strong {
        background: linear-gradient(90deg, #f8ff00 0%, #3ad59f 100%);
        border-radius: 0.2em;
        padding: 0.3em;
        display: inline-block;
      }
      margin-top: 0.5em;
      text-align: left;
      line-height: 1.3em;
      h2 {
        text-align: center;
      }
      font-weight: 300;
      a {
        color: black !important;
        font-weight: 600;
      }
      // height: 400px;
      // color: white;
      box-sizing: border-box;
      padding: 0.5em;
      // border-radius: 0.2em;
      // background: rgba(50,50,50,0.7);
      // background: black;
      border: 2px solid white;
      border-radius: 0.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      font-size: 1em;
      // padding: 1em 0 0 0.3em;
      // background: #138a81;
      // color: white;
      small {
        font-size: 0.8em;
      }
      .site {
        font-size: 0.7em;
        a {
          font-weight: 300;
        }
      }
      ul {

      }
      margin: 0;
      font-family: $sigFont;

      overflow: hidden;
      .welcome {
        color: rgb(220,220,220);
        font-size: 0.5em;
        // font-style: italic;
        font-weight: 200;
        text-transform: uppercase;
      }
      @media screen and (max-width: 550px) {
        font-size: 0.7em;
      }
      .hero {
        margin-right: 1em;
        img {
          width: 100%;
          border-radius: 0.5em;
        }
        max-width: 250px;
        @media screen and (max-width: 550px) {
          img {
            border-radius: 0 0.5em 0.5em 0;
          }

        }
      }
      .tag-line {
        // width: 250px;
        @media screen and (max-width: 620px) {
          // margin-left: -45%;
        }
        .order-online-button {
          // display: inline-block;
        }
      }
      p {
        // margin: 1em 0;
      }
      .small {
        font-size: 0.5em;
      }
    }
  }
  .food-gallery {
    margin-top: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .photo {
      text-align: center;

      width: 16.666%;
      box-sizing: border-box;
      padding: 0.3em;
      @media screen and (max-width: 500px) {
        width: 33.333%;
      }
      max-width: 200px;
      a {
        // background: $red;
        font-weight: 600;
        font-size: 0.75em;
        color: inherit;

      }
      img {
        border-radius: 0.5em;
        width: 100%;
        display: block;
      }
    }
  }
  .menu-header {
    font-family: $deco;
    font-size: 2em;
    font-weight: 600;
    margin: 0.5em 0;
    color: $red;

  }

  .location-info {
    padding: 1em 0.5em;
    .text {
      display: grid;
      grid-gap: 0.4em;
      grid-template-columns: auto auto auto;
      @media screen and (max-width: 680px) {
        display: block;

        text-align: center;

      }
    }
    p {
      font-size: 0.8em;
    }
    .location {
      grid-column: 1/2;
    }
    .fax, .telephone {
      grid-column: 2/3;
    }
    .hours-section {
      grid-column: 3/4;
    }


    // text-align: center;
    h2 {
      font-family: $deco;
      font-size: 1.3em;
      font-weight: 600;
      margin: 0.5em 0;
      color: $red;
    }
    p {
      margin: 0;
      line-height: 1.5em;
    }
    .hours-section {
      max-width: 280px;
      margin: 0 auto;
    }
    .hours-details {
      display: grid;
      grid-template-columns: auto 1fr;

      .days {
        grid-column: 1/2;
        padding-right: 0.4em;
        text-align: left;
      }
      .hours {
        text-align: left;
        grid-column: 2/3
      }
    }
  }
  .news {
    max-width: 850px;
    margin: 0 auto;
    margin-top: 1.8em;
    padding: 0 0.5em;
    display: flex;
    // flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    .hero {
      width: 70px;
      margin-right: 0.5em;
      img {
        width: 100%;
        border-radius: 0.3em;
      }
    }
    .full-menu {
      margin-top: 1.5em;

      text-align: right;
      a {
        margin-left: 0.5em;
        background: black;
        color: white;
        padding: 0.2em 0.3em;
        border-radius: 0.2em;
        text-decoration: none;
      }
    }
    h2, h3 {
      font-weight: 900;
      font-size: 1.2em;
      // text-transform: uppercase;
      // margin-bottom: 1em;
      font-family: $deco;
    }
    a {
      // text-transform: uppercase;
      // font-family: $deco;
    }
  }
  .features {
    padding: 2em 0;
  }
  .feature-section.visit-us {
    img {
      // width: in;
      height: auto !important;
      object-fit: none;
    }
  }
  .feature-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em 0em;
    .photo {
      width: 40%;
      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-radius: 0.2em;
      }

    }
    .content {
      box-sizing: border-box;
      width: 40%;
      padding-left: 1.5em;
    }
    @media screen and (max-width: 600px) {
      display: block;
      .photo, .content {
        width: 100%;
        padding: 1em 0.5em;
      }
      .photo {
        padding: 1em 0;
      }
    }
    h2, h3 {
      font-weight: 900;
      font-size: 1.2em;
      // text-transform: uppercase;
      margin-bottom: 1em;
      font-family: $deco;
    }
    .text {
      font-size: 0.75em;
      line-height: 1.5em;
      font-weight: 400;
    }
    a.link {
      font-weight: 600;
      // text-decoration: none;
    }

  }
  .order-benefits-wrapper {

    // background: url(https://afag.imgix.net/hayats-kitchen/hero-1.jpg?w=1200) no-repeat;
    background-size: cover;
    background-position: center 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: white;
      background: black;
      padding: 0.2em 0.4em;
    }
  }
  .landing-page .text {
    color: white;
    text-shadow: 1.5px 1.5px 1.5px black;
    padding: 0.5em 0.8em;
    max-width: 360px;
  }

  a.checkout-button.button {
    background: $red;
    font-weight: 600;
    box-shadow: none;
    border: none;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  color: black;

  font-family: $deco;
  font-weight: 600;
  // font-style: italic;
  // text-transform: uppercase;
  // text-decoration: underline;
  font-size: 1.5em;
  // line-height: 0.7em;
  // padding: 0.3em 0.3em 0.2em 0.3em;
  border-radius: 3px;
  background: none;
  position: relative;
}

.menu-category-list-view {
  .menu-item {
    background: none;
    // border-bottom: 1px dashed #bdc0ba;
    border-radius: 0;
    // padding: 0 0.1em;
    box-sizing: border-box;
  }
  // text-transform: lowercase;
  .food-name {
    font-weight: 600 !important;
    // font-family: $deco;
    // font-size: 1.2em;
  }
  .price {
    // font-family: $deco;
  }
  .content {
    // font-size: 1.3em;
    // width: 70%;
  }
}

// WINE products special display
.category.wine {
  .menu-category-list-view {

  }
}

.top-banner {
  // min-height: 40vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  // color: white;
  padding: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // text-shadow: 1px 1px 0 $red;
  .text {
    // display: inline-block;
    // background: black;
    padding: 0.4em 0.5em;
    border-radius: 0.2em;
    // text-transform: uppercase;
    h1 {
      font-weight: 900;
      text-transform: uppercase;
      font-family: $deco;
    }
  }
}

.pages-nav {
  font-size: 0.5em;

  max-width: $max-site-width;
  margin: 0 auto;

  font-family: $sigFont;
  // font-style: italic;
  background: $white;
  // text-transform: uppercase;
  // font-weight: 800;

  display: flex;
  flex-wrap: warp;
  align-items: center;
  .react-toggle {
    margin-right: 0.5em;
  }
  ul {
    padding: 0.5em 0;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    align-items: stretch;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}
a.page-link {

  text-decoration: none;
  color: $black;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 0.1em;
  // text-transform: lowercase;
  // font-size: 0.8em;
  line-height: 0.7em;
  // letter-spacing: -0.09em;
  // padding: $paddingY 0.3em;
  padding: $paddingY 0.2em calc(#{$paddingY} - #{$borderWidth}) 0.2em;
  // margin: 0.4em 0.05em;
  // padding: 0.2em 0.2em;
  // box-sizing: border-box;
  // border: $borderWidth solid $white;
  // border-top: none;
  // border-bottom: none;
  border-radius: 0.2em;

  &:hover, &.active {
    color: white;
    background: black;
    // border: $borderWidth solid white;
    // border-top: none;
    // border-bottom: none;

  }
}

a.order-from-old {
  font-size: 0.4em;
  display: inline-block;
  color: white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: black;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
  .menu-item {
    header {
      // font-family: $sigFont;
      // font-size: 1.2em;
    }
  }

  .footer {
    margin-top: 4em;
    background: black;
  }

}

.page-title {

  font-family: $deco;
  font-weight: 600;
  // font-size: 2em;
  padding: 1em 0;
  text-align: center;
}

.photos {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  .photo-wrapper {

    width: 45%;
    img {
      border: 4px solid black;
      width: 100%;
    }
  }
}


.menu-category-list-view {
  .mag-glass-svg {
    display: none;

  }
}


.page-title {
  text-align: left;
  font-size: 1.5em;
  font-weight: 900;
  font-family: $deco;
  text-transform: uppercase;
  margin-top: 1em;
}
.contact-page {
  .top-banner {
    margin: 1em 0;
    width: 100%;
  }
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5em;
  strong {
    font-weight: 600;
  }
  h2 {
    font-weight: 900;
    font-family: $deco;
    text-transform: uppercase;
    margin: 1em 0;
  }
  ul {
    list-style: disc;
    padding-left: 1em;
  }
  li, p {
    margin: 0.5em 0;
  }
}
